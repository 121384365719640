var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form-horizontal",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" Register Form ")])]),_c('div',[_c('ValidationProvider',{attrs:{"name":"required","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The Text field is required': null,"hasSuccess":passed,"name":"requiredText"},model:{value:(_vm.required),callback:function ($$v) {_vm.required=$$v},expression:"required"}}),_c('div',{attrs:{"slot":"infoBlock"},slot:"infoBlock"},[_c('code',[_vm._v("required:true")])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The Email field is required': null,"hasSuccess":passed,"name":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('div',{attrs:{"slot":"infoBlock"},slot:"infoBlock"},[_c('code',[_vm._v("email:true")])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"number","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The Number field is required': null,"hasSuccess":passed,"name":"number"},model:{value:(_vm.number),callback:function ($$v) {_vm.number=$$v},expression:"number"}}),_c('div',{attrs:{"slot":"infoBlock"},slot:"infoBlock"},[_c('code',[_vm._v("number:true")])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"url","rules":{
            required: true,
            regex: /(https?:\/\/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9])(:?\d*)\/?([a-z_\/0-9\-#.]*)\??([a-z_\/0-9\-#=&]*)/g
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var passed = ref.passed;
          var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The Url field is required': null,"hasSuccess":passed,"name":"url"},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}}),_c('div',{attrs:{"slot":"infoBlock"},slot:"infoBlock"},[_c('code',[_vm._v("url:true")])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"equal","rules":"confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var passed = ref.passed;
          var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The idDestination confirmation does not match': null,"hasSuccess":passed,"name":"idSource","placeholder":"#idSource"},model:{value:(_vm.idSource),callback:function ($$v) {_vm.idSource=$$v},expression:"idSource"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"equalTo","vid":"confirmation"}},[_c('fg-input',{attrs:{"type":"text","name":"idDestination","placeholder":"#idDestination"},model:{value:(_vm.idDestination),callback:function ($$v) {_vm.idDestination=$$v},expression:"idDestination"}}),_c('div',{attrs:{"slot":"infoBlock"},slot:"infoBlock"},[_c('code',[_vm._v("confirmed: 'idSource'")])])],1)],1),_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-fill btn-info btn-wd",attrs:{"type":"submit"}},[_vm._v("Validate inputs")])])])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }